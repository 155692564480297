<template>
  <div class="view pa24">
    <el-button
        class="mr10"
        type="primary"
        @click="
        centerDialogVisible = true;
        bdetermine = true;
        editUpdate = false;
      "
    >+ 添加</el-button>
    <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :total="total"
    >
      <template v-slot:table>
        <el-table-column label="账号" prop="account" align="center"></el-table-column>
        <el-table-column label="商户号" prop="mch_id" sortable align="center"></el-table-column>
<!--        <el-table-column label="类型" prop="group_id" align="center" :filters="[{text:'商户',value:1},{text:'代理',value:2}]"-->
<!--                         :filter-multiple="false"-->
<!--                         :filter-method="filterHandler">-->
<!--          <template slot-scope="scope">-->
<!--            <el-tag type="warning" v-if="scope.row.group_id == 1" >商户</el-tag>-->
<!--            <el-tag type="success" v-else >代理</el-tag>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column label="状态" prop="is_disable" align="center" :filters="[{text:'正常',value:0},{text:'锁定',value:1}]"
                         :filter-multiple="false"
                         :filter-method="filterHandler">
          <template slot-scope="scope">
            <el-tag type="danger" v-if="scope.row.is_disable == 1" >锁定</el-tag>
            <el-tag type="success" v-else >正常</el-tag>
          </template>
        </el-table-column>
<!--        <el-table-column label="上级商户号" align="center" prop="agent_id"></el-table-column>-->
        <el-table-column label="注册时间" align="center" prop="create_time"></el-table-column>
        <el-table-column label="余额" align="center" sortable>
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.money }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="代付余额" align="center" sortable>
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.dfmoney }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="备注" align="center" prop="notes"></el-table-column>
<!--        <el-table-column label="操作" align="center">-->
<!--          <template slot-scope="scope">-->
<!--            <el-button-group>-->
<!--              <el-button text type="primary" size="small" @click="table_edit(scope.row)">查看费率</el-button>-->
<!--            </el-button-group>-->
<!--          </template>-->
<!--        </el-table-column>-->
      </template>
    </commonTable>
    <el-dialog
        title="新增商户"
        :visible.sync="centerDialogVisible"
        :modal-append-to-body="false"
        width="50%"
        center
        @close="close"
    >
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="150px"
      >
        <el-form-item label="昵称" prop="nickname">
          <el-input type="text" v-model="ruleForm.nickname" clearable placeholder="请输入昵称"></el-input>
        </el-form-item>
        <el-form-item label="账号" prop="account">
          <el-input type="text" v-model="ruleForm.account" clearable placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item label="登录密码" prop="newPassword">
          <el-input type="password" v-model="ruleForm.newPassword" clearable placeholder="请输入登录密码"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="qrPassword">
          <el-input type="password" v-model="ruleForm.qrPassword" clearable placeholder="请确认密码"></el-input>
        </el-form-item>
        <el-form-item label="支付密码" prop="pay_password">
          <el-input type="password" v-model="ruleForm.pay_password" clearable placeholder="请输入支付密码"></el-input>
        </el-form-item>
        <el-form-item label="是否禁用" prop="is_disable">
          <el-switch
              v-model="ruleForm.is_disable"
              active-text="开启"
              inactive-text="关闭">
          </el-switch>
        </el-form-item>
<!--        <el-form-item label="备注" prop="desc">-->
<!--          <el-input v-model="ruleForm.desc" clearable placeholder="请输入"></el-input>-->
<!--        </el-form-item>-->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine" v-if="bdetermine"
        >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {childerlist,addChilderUser} from "@/api/settlement";
export default {
  name:'shlist',
  data() {
    return {
      typelist:[
        {
          value: 0,
          label: '商户'
        }, {
          value: 1,
          label: '代理',
          disabled: true
        },
      ],
      isSaveing:false,
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      centerDialogVisible: false,
      bEdit: false,
      bdetermine: true,
      editUpdate:true,
      ruleForm: {
        nickname: "",
        account: "",
        newPassword:"",
        qrPassword:"",
        pay_password: "",
        is_disable:false
      },
      rules: {
        newPassword: [
          {required: true, message: '请输入登录密码'}
        ],
        qrPassword: [
          {required: true, message: '请确认密码'}
        ],
        account: [
          {required: true, message: '请输入账号'}
        ],
        nickname: [
          {required: true, message: '请输入昵称'}
        ],
        is_disable: [
          {required: true, message: '请选择'}
        ],
      },
    };
  },
  components: {
    commonTable,
  },
  mounted(){
    this.queryPage();
  },

  methods: {
    filterHandler(value, row, column) {
      const property = column['property']
      return row[property] === value
    },
    async queryPage() {
      let data = {
        page_size: this.pageSize,
        page_no: this.currentPage,
      };
      try {
        this.loading = true;
        const result = await childerlist(data);
        this.loading = false;
        const { count, lists } = result.data;
        this.tableData = lists;
        this.total = count;
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    edit(val, b) {
      let data = JSON.parse(JSON.stringify(val));
      this.centerDialogVisible = true;
      this.ruleForm.account = data.windowsName ;
      this.ruleForm.bank = data.windowsPerson;
      this.ruleForm.zhihang = data.status;
      this.ruleForm.name = data.officeId
      this.ruleForm.ka = data.officeId
      this.ruleForm.desc = data.officeId
      this.bdetermine = b;
      this.bEdit = b;
      this.windowsConfigId = data.windowsConfigId
    },
    /**@method 添加 */
    determine() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              account:this.ruleForm.account,
              nickname:this.ruleForm.nickname,
              password:this.ruleForm.newPassword,
              password_confirm:this.ruleForm.qrPassword,
              pay_password:this.ruleForm.pay_password,
              is_disable:this.ruleForm.is_disable?1:0,
            };
            addChilderUser(data)
                .then((res) => {
                  console.log(res)
                  if (res.code === 1) {
                    this.centerDialogVisible = false;
                    this.$message.closeAll();
                    this.$message({
                      message: "保存成功",
                      type: "success",
                    });
                    this.queryPage();
                  } else {
                    this.$message.error(res.msg);
                    return;
                  }
                })
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val) {
      try {
        await bankDel({ id: val });
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    /**@method 模态框关闭的回调 */
    close() {
      (this.ruleForm = {
        account: "",
        bank:"",
        zhihang: "",
        name:"",
        desc: "",
        ka:"",
      }),
          this.centerDialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>




